import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"

import styles from "./404.module.scss"

const NotFoundPage = () => {
  const image = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { eq: "404-images/Lost Tourist-big.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="XINRAN WANG" />
      <div className={styles.wrapper}>
        <div>
          <Img fluid={image.placeholderImage.childImageSharp.fluid} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h1 style={{ marginTop: 32, marginBottom: 8 }}>Got lost?</h1>
            <div>
              <Link to="/">Go back to the homepage</Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
